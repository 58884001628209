<template>
  <div>
    <Modal :modalVisible="isModalFeedbackDocument" @close="hideModalFeedbackDocument()" id="feedback">
      <template slot="modal-content">
        <div class="text-center my-6">
          <p class="text-2xl font-bold mb-5">Are you sure to {{ action }}{{ action === 'mark as complete' ? '' : ' document' }}?</p>
          <div class="flex justify-center items-center gap-2">
            <Button buttonText="Yes" type="secondary" @action="actionFeedback(action)" />
            <Button buttonText="No" type="tertiary" @action="hideModalFeedbackDocument()" />
          </div>
        </div>
      </template>
    </Modal>
    <Modal class="overflow-y-hidden" height="430px" width="500px" :modalVisible="isModalAddDocument" @close="hideModalAddDocument()" id="addDocument">
      <template slot="modal-content">
        <h1 class="text-2xl font-bold mb-3 mt-5">Add Document Request</h1>
        <div class="mb-8">
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Document Type</label>
          <Dropdown :options="documentTypeOptions" optionLabel="name" v-model="selectedDocumentType" :default="selectedDocumentType" placeholder="Select document type" />
        </div>
        <div class="mb-8">
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
            >Uploader <span v-if="!selectedDocumentType" class="inline-block text-neutral-300">(Document type required)</span></label
          >
          <Dropdown :disabled="!selectedDocumentType" :options="uploaderOptions" v-model="selectedUploader" :default="selectedUploader" placeholder="Select uploader" />
        </div>
        <div class="text-left mb-8">
          <input type="checkbox" id="template" v-model="isTemplateAvailable" class="styled-checkbox" />
          <label for="template" class="text-sm">Provide document template</label>
        </div>
        <div class="flex justify-center items-center my-5">
          <Button :disabled="!canAddDocument" class="mr-5" buttonText="Add Document" type="secondary" @action="addDocument()" />
          <Button buttonText="Cancel" type="tertiary" @action="hideModalAddDocument()" />
        </div>
      </template>
    </Modal>
    <Modal class="overflow-y-hidden" width="500px" :modalVisible="isModalUploadDocument" @close="hideModalUploadDocument()" id="uploadDocument">
      <template slot="modal-content">
        <div class="p-7">
          <p class="text-center text-xl font-bold mb-2">Upload Document</p>
          <div class="flex justify-center items-center">
            <div class="w-full py-5 text-center">
              <div :class="`p-5 h-48 bg-white border rounded-lg border-dashed ${fileDocument ? 'border-yellow' : 'border-gray-300'}`" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <div v-if="fileDocument" class="h-full">
                  <div class="flex justify-end items-center">
                    <div class="cursor-pointer rounded-full hover:opacity-80" @click="remove()">
                      <CrossCircle color="red" height="24" width="24" />
                    </div>
                  </div>
                  <div class="flex flex-col justify-center items-center">
                    <DocumentText color="yellow" height="50" width="50" class="mb-2" />
                    <p class="text-yellow">{{ newName.length > 70 ? newName.substr(0, 68) + '..' : newName }}</p>
                  </div>
                </div>
                <div v-else>
                  <div class="flex justify-center mb-5">
                    <img src="@/assets/images/vector.svg" />
                  </div>
                  <input type="file" id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="file" accept=".pdf,.jpg,.jpeg,.png,.webp" />
                  Drag & Drop File
                  <br />or
                  <label for="assetsFieldHandle" class="block">
                    <div class="flex justify-center items-center">
                      <div class="cursor-pointer font-bold rounded-lg border text-xs py-2 px-3 text-white border-yellow bg-yellow hover:border-yellow-300 hover:bg-yellow-300">Select File</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center items-center my-5">
            <Button :disabled="!fileDocument" class="mr-5" buttonText="Upload" type="secondary" @action="forGetUploadUrl()" />
            <Button buttonText="Cancel" type="tertiary" @action="hideModalUploadDocument()" />
          </div>
        </div>
      </template>
    </Modal>
    <div class="mb-6 flex justify-start items-center">
      <router-link :to="`/crm/student/${studentId}`" class="flex justify-start items-center">
        <ArrowForward direction="left" width="25" height="25" />
      </router-link>
      <h1 class="text-3xl font-bold ml-3 hover:no-underline">Student Class Detail</h1>
    </div>
    <div v-if="isLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else class="min-h-screen">
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="px-2 flex-1 flex-shrink-0">
          <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
            <span class="-ml-1"><Label color="yellow" /></span>Profile Detail
          </p>
          <div class="mb-6">
            <div class="mb-3">
              <p class="text-xs font-semibold text-neutral-400">Full Name:</p>
              <p class="font-bold">{{ studentName }}</p>
            </div>
            <div class="mb-3">
              <p class="text-xs font-semibold text-neutral-400">Email:</p>
              <p class="font-bold">{{ studentEmail }}</p>
            </div>
            <div class="mb-3">
              <p class="text-xs font-semibold text-neutral-400">Phone Number:</p>
              <p class="font-bold">{{ studentPhone }}</p>
            </div>
          </div>
        </div>
        <div class="px-2 flex-1 flex-shrink-0">
          <p class="font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1">
            <span class="-ml-1"><Label color="yellow" /></span>Class
          </p>
          <div class="mb-6">
            <div class="mb-3">
              <p class="text-xs font-semibold text-neutral-400">Program:</p>
              <p class="font-bold">{{ programName }}</p>
            </div>
            <div class="mb-3">
              <p class="text-xs font-semibold text-neutral-400">Class:</p>
              <p class="font-bold">{{ coreClassName }}</p>
            </div>
            <div class="mb-3">
              <p class="text-xs font-semibold text-neutral-400">Payment Method:</p>
              <p class="font-bold">{{ paymentMethodName }}</p>
            </div>
          </div>
        </div>
        <div class="px-2 flex-1 flex-shrink-0">
          <div class="flex justify-between border-b border-neutral-50 pb-2 mb-4">
            <p class="font-semibold flex gap-1 items-center">
              <span class="-ml-1"><Label color="yellow" /></span>Administration Document
            </p>
            <div class="flex justify-between items-center">
              <Button :disabled="statusId !== '1'" class="mr-2" buttonText="Mark as Complete" type="secondary" @action="actionDocument('-', 'mark as complete')" />
              <Button :disabled="statusId !== '1'" buttonText="Add Document" @action="showModalAddDocument" />
            </div>
          </div>
          <div class="mb-6">
            <vue-good-table
              v-if="administrationList.length > 0"
              mode="remote"
              :rows="administrationList"
              :columns="tableHeader"
              :sort-options="{
                enabled: false
              }"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'action'" class="flex flex-col gap-2 whitespace-nowrap">
                  <div class="flex justify-center items-center">
                    <Button
                      :disabled="props.row.uploader.toLowerCase() === 'student' || statusId !== '1' ? true : false"
                      class="mr-2"
                      type="primary"
                      size="icon"
                      :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Uploads')"
                      :tooltip="{ show: true, position: '70%', text: 'Upload File' }"
                      @action="actionDocument(props.row.id, 'upload')"
                    />
                    <Button
                      :disabled="props.row.documentUrl === '' || props.row.status.toLowerCase() === 'approved' || statusId !== '1'"
                      class="mr-2"
                      type="greenTertiary"
                      size="icon"
                      :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/CheckmarkCircle')"
                      :tooltip="{ show: true, position: '120%', text: 'Approve' }"
                      @action="actionDocument(props.row.id, 'approve')"
                    />
                    <Button
                      :disabled="props.row.documentUrl === '' || props.row.status.toLowerCase() === 'rejected' || statusId !== '1'"
                      class="mr-2"
                      type="deleteTertiary"
                      size="icon"
                      :forIcon="{ height: '24', width: '24', color: props.row.documentUrl === '' || props.row.status.toLowerCase() === 'rejected' || statusId !== '1' ? 'white' : 'red' }"
                      :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/CrossCircle')"
                      :tooltip="{ show: true, position: '140%', text: 'Reject' }"
                      @action="actionDocument(props.row.id, 'reject')"
                    />
                    <Button
                      :disabled="statusId !== '1'"
                      type="delete"
                      size="icon"
                      :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                      :tooltip="{ show: true, position: '140%', text: 'Delete' }"
                      @action="actionDocument(props.row.id, 'delete')"
                    />
                  </div>
                </div>
                <div v-else-if="props.column.field == 'document'">
                  <p @click="viewDocument(props.row['documentUrl'])" :class="props.row['documentUrl'] !== '' ? 'text-yellow cursor-pointer' : ''">{{ props.row['document'] }}</p>
                  <p class="text-sm">
                    Template: <span @click="viewDocument(props.row['templateUrl'])" :class="props.row['templateUrl'] !== '' ? 'text-yellow cursor-pointer' : ''">{{ props.row['template'] }}</span>
                  </p>
                </div>
                <div v-else>
                  {{ props.row[props.column.field] }}
                </div>
              </template>
            </vue-good-table>
            <div v-else class="border rounded-lg h-64 flex justify-center items-center">
              <div class="bg-pink">
                <p class="text-center text-neutral-400">No data available</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
// import { showVueToast } from '@/utils'
export default {
  name: 'StudentClassDetail',
  components: {
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    CrossCircle: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/CrossCircle'),
    DocumentText: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/DocumentText'),
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label')
  },
  data: function () {
    return {
      isLoading: true,
      isModalAddDocument: false,
      isModalUploadDocument: false,
      isTemplateAvailable: false,
      isModalFeedbackDocument: false,
      action: null,
      templateTemporaryUrl: null,
      fileDocument: null,
      selectedDocumentType: null,
      selectedUploader: null,
      programName: null,
      coreClassName: null,
      paymentMethodName: null,
      studentName: null,
      studentEmail: null,
      studentPhone: null,
      newName: null,
      documentId: null,
      typeFileDocument: '',
      maxFileDocument: 20000000,
      uploaderOptions: ['Admin', 'Student', 'Both'],
      documentTypeOptions: [],
      originalAdministrationList: [],
      administrationList: []
    }
  },
  computed: {
    customerId() {
      return localStorage.getItem('client')
    },
    studentId() {
      return this.$route.params.studentId
    },
    statusId() {
      return this.$route.params.statusId
    },
    studentClassId() {
      return this.$route.params.id
    },
    tableHeader() {
      return [
        { field: 'documentType', label: 'Document Type' },
        { field: 'document', label: 'Document' },
        { field: 'uploader', label: 'Uploader' },
        { field: 'status', label: 'Status' },
        { field: 'lastUpdate', label: 'Last Update' },
        { field: 'action', label: 'Action' }
      ]
    },
    canAddDocument() {
      return this.selectedDocumentType && this.selectedUploader
    }
  },
  watch: {
    isTemplateAvailable: function () {
      this.isTemplateAvailable ? this.showModalUploadDocument() : (this.templateTemporaryUrl = this.newName = null)
    }
  },
  mounted() {
    this.getDocumentTypeOptions()
    this.getStudentClassDetail()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('student', [
      'GET_CRM_STUDENTCLASS_BY_ID',
      'GET_REGISTRATION_DOCUMENTS',
      'ADD_REGISTRATION_DOCUMENTS',
      'UPLOAD_REGISTRATION_DOCUMENTS_BY_ADMIN',
      'APPROVE_REGISTRATION_DOCUMENTS',
      'REJECT_REGISTRATION_DOCUMENTS',
      'DELETE_REGISTRATION_DOCUMENTS',
      'MARK_AS_COMPLETE_ADMINISTRATION'
    ]),
    statusFriendly(status) {
      if (status) {
        const arrayStatus = status.split(' ')
        let newArrayStatus = []
        for (let i = 0; i < arrayStatus.length; i++) {
          const subArrayStatus = arrayStatus[i].split('_')
          if (subArrayStatus.length > 1) {
            let subNewArrayStatus = []
            for (let j = 0; j < subArrayStatus.length; j++) {
              newArrayStatus.push(`${subArrayStatus[j][0].toUpperCase()}${subArrayStatus[j].substr(1).toLowerCase()}`)
            }
          } else {
            newArrayStatus.push(`${arrayStatus[i][0].toUpperCase()}${arrayStatus[i].substr(1).toLowerCase()}`)
          }
        }
        return newArrayStatus.join(' ')
      } else {
        return '-'
      }
    },
    onChange() {
      this.showLoading()
      const file = this.$refs.file.files[0]
      let reader = new FileReader()
      reader.onload = (e) => {
        if (e.target.result.length > this.maxFileDocument) {
          this.showVueToast('File is larger than 20MB!', 'error', 2000)
        } else {
          this.newName = file.name
          this.typeFileDocument = file.type
          this.fileDocument = e.target.result
        }
      }
      reader.readAsDataURL(file)
      setTimeout(() => this.hideLoading(), 2500)
    },
    remove() {
      this.fileDocument = null
    },
    dragover(event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('border-yellow')) {
        event.currentTarget.classList.remove('border-gray-300')
        event.currentTarget.classList.add('border-yellow')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('border-yellow')
      event.currentTarget.classList.add('border-gray-300')
    },
    drop(event) {
      if (!this.fileDocument) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange()

        event.currentTarget.classList.add('border-gray-300')
        event.currentTarget.classList.remove('border-yellow')
      } else {
        this.showLoading()
        this.showVueToast('Only 1 file can be upload!', 'error', 2000)
        setTimeout(() => this.hideLoading(), 2500)
      }
    },
    forGetUploadUrl() {
      this.showLoading()
      let baseUrl = ''
      switch (process.env.NODE_ENV) {
        case 'production':
          baseUrl = 'https://prod-api-v4.g2academy.co'
          break
        case 'staging':
          baseUrl = 'https://staging-api-v4.g2academy.co'
          break
        case 'beta':
          baseUrl = 'https://dev-api-v4.g2academy.co'
          break
      }
      axios
        .get(`${baseUrl}/uploads?contentType=${this.typeFileDocument}`)
        .then((res) => this.uploadDocument(res))
        .catch((res) => {
          if (res.errors) {
            this.showVueToast(`${res.errors.error}!`, 'error', 2000)
          } else {
            this.showVueToast('Failed to upload document, please try again later!', 'error', 2000)
          }
          setTimeout(() => this.hideLoading(), 2500)
        })
    },
    uploadDocument(response) {
      let binary = atob(this.fileDocument.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: this.typeFileDocument })
      fetch(response.data.uploadUrl, {
        method: 'PUT',
        body: blobData
      })
        .then(() => {
          this.templateTemporaryUrl = response.data.uploadUrl.split('?')[0]
          this.isTemplateAvailable ? this.uploadTemplate() : this.uploadByAdmin()
        })
        .catch((res) => {
          if (res.errors) {
            this.showVueToast(`${res.errors.error}!`, 'error', 2000)
          } else {
            this.showVueToast('Failed to upload document, please try again later!', 'error', 2000)
          }
          setTimeout(() => this.hideLoading(), 2500)
        })
    },
    uploadTemplate() {
      this.showVueToast('Upload document successfully!', 'success', 2000)
      setTimeout(() => {
        this.hideLoading()
        this.remove()
        this.isModalUploadDocument = false
        this.isTemplateAvailable = true
      }, 2500)
    },
    actionDocument(id, action) {
      this.documentId = id
      this.action = action
      switch (action) {
        case 'upload':
          this.showModalUploadDocument()
          break
        default:
          this.showModalFeedbackDocument()
          break
      }
    },
    actionFeedback(action) {
      switch (action) {
        case 'approve':
          this.approveDocument()
          break
        case 'reject':
          this.rejectDocument()
          break
        case 'mark as complete':
          this.markAsComplete()
          break
        case 'delete':
          this.deleteDocument()
          break
      }
    },
    uploadByAdmin() {
      this.showLoading()
      this.UPLOAD_REGISTRATION_DOCUMENTS_BY_ADMIN({
        studentClassId: this.studentClassId,
        studentRegistrationDocumentId: this.documentId,
        payload: {
          temporaryUrl: this.templateTemporaryUrl,
          fileName: this.newName
        }
      })
        .then(() => {
          this.showVueToast('Upload document successfully!', 'success', 2000)
          this.hideModalUploadDocument()
          this.getStudentClassDetail()
        })
        .catch((res) => {
          if (res.errors) {
            this.showVueToast(`${res.errors.error}!`, 'error', 2000)
          } else {
            this.showVueToast('Failed to upload document, please try again later!', 'error', 2000)
          }
        })
      setTimeout(() => {
        this.hideLoading()
      }, 2500)
    },
    approveDocument() {
      this.showLoading()
      this.APPROVE_REGISTRATION_DOCUMENTS({
        studentClassId: this.studentClassId,
        studentRegistrationDocumentId: this.documentId
      })
        .then(() => {
          this.showVueToast('Approve document successfully!', 'success', 2000)
          this.hideModalFeedbackDocument()
          this.getStudentClassDetail()
        })
        .catch((res) => {
          if (res.errors) {
            this.showVueToast(`${res.errors.error}!`, 'error', 2000)
          } else {
            this.showVueToast('Failed to approve document, please try again later!', 'error', 2000)
          }
        })
      setTimeout(() => {
        this.hideLoading()
      }, 2500)
    },
    rejectDocument() {
      this.showLoading()
      this.REJECT_REGISTRATION_DOCUMENTS({
        studentClassId: this.studentClassId,
        studentRegistrationDocumentId: this.documentId
      })
        .then(() => {
          this.showVueToast('Reject document successfully!', 'success', 2000)
          this.hideModalFeedbackDocument()
          this.getStudentClassDetail()
        })
        .catch((res) => {
          if (res.errors) {
            this.showVueToast(`${res.errors.error}!`, 'error', 2000)
          } else {
            this.showVueToast('Failed to reject document, please try again later!', 'error', 2000)
          }
        })
      setTimeout(() => {
        this.hideLoading()
      }, 2500)
    },
    markAsComplete() {
      this.showLoading()
      this.MARK_AS_COMPLETE_ADMINISTRATION({
        studentClassId: this.studentClassId
      })
        .then(() => {
          this.showVueToast('Mark as complete successfully!', 'success', 2000)
          this.hideModalFeedbackDocument()
          this.$router.push(`/crm/student/${this.studentId}`)
        })
        .catch((res) => {
          if (res.errors) {
            this.showVueToast(`${res.errors.error}!`, 'error', 2000)
          } else {
            this.showVueToast('Failed to mark as complete, please try again later!', 'error', 2000)
          }
        })
      setTimeout(() => {
        this.hideLoading()
      }, 2500)
    },
    deleteDocument() {
      this.showLoading()
      this.DELETE_REGISTRATION_DOCUMENTS({
        studentClassId: this.studentClassId,
        studentRegistrationDocumentId: this.documentId
      })
        .then(() => {
          this.showVueToast('Delete document successfully!', 'success', 2000)
          this.hideModalFeedbackDocument()
          this.getStudentClassDetail()
        })
        .catch((res) => {
          if (res.errors) {
            this.showVueToast(`${res.errors.error}!`, 'error', 2000)
          } else {
            this.showVueToast('Failed to delete document, please try again later!', 'error', 2000)
          }
        })
      setTimeout(() => {
        this.hideLoading()
      }, 2500)
    },
    showModalAddDocument() {
      this.isModalAddDocument = true
    },
    hideModalAddDocument() {
      this.hideModalUploadDocument()
      this.selectedDocumentType = this.selectedUploader = null
      this.isModalAddDocument = false
    },
    showModalFeedbackDocument() {
      this.isModalFeedbackDocument = true
    },
    hideModalFeedbackDocument() {
      this.isModalFeedbackDocument = false
    },
    showModalUploadDocument() {
      this.isModalUploadDocument = true
    },
    hideModalUploadDocument() {
      this.remove()
      this.templateTemporaryUrl = this.newName = null
      this.isModalUploadDocument = this.isTemplateAvailable = false
    },
    viewDocument(url) {
      if (url !== '') window.open(url)
    },
    addDocument() {
      this.showLoading()
      this.ADD_REGISTRATION_DOCUMENTS({
        studentClassId: this.studentClassId,
        payload: {
          registrationDocumentId: this.selectedDocumentType.id,
          uploader: this.selectedUploader,
          templateFileName: this.newName,
          templateTemporaryUrl: this.templateTemporaryUrl
        }
      })
        .then(() => {
          this.showVueToast('Add document successfully!', 'success', 2000)
          this.hideModalAddDocument()
          this.getStudentClassDetail()
        })
        .catch((res) => {
          if (res.errors) {
            this.showVueToast(`${res.errors.error}!`, 'error', 2000)
          } else {
            this.showVueToast('Failed to add document, please try again later!', 'error', 2000)
          }
        })
      setTimeout(() => {
        this.hideLoading()
      }, 2500)
    },
    getDocumentTypeOptions() {
      this.GET_REGISTRATION_DOCUMENTS({}).then((res) => (this.documentTypeOptions = res.data.data))
    },
    getStudentClassDetail() {
      this.administrationList = []
      this.showLoading()
      this.GET_CRM_STUDENTCLASS_BY_ID({
        studentId: this.studentId,
        studentClassId: this.studentClassId
      }).then((res) => {
        this.studentName = res.data.data.studentName ? res.data.data.studentName : '-'
        this.studentEmail = res.data.data.studentEmail ? res.data.data.studentEmail : '-'
        this.studentPhone = res.data.data.studentPhone ? res.data.data.studentPhone : '-'
        this.programName = res.data.data.programName ? res.data.data.programName : '-'
        this.coreClassName = res.data.data.className ? res.data.data.className : '-'
        this.paymentMethodName = res.data.data.paymentMethodName ? res.data.data.paymentMethodName : '-'
        this.originalAdministrationList = res.data.data.registrationDocuments ? res.data.data.registrationDocuments : []
        if (res.data.data.registrationDocuments) {
          res.data.data.registrationDocuments.forEach((element) => {
            this.administrationList.push({
              id: element.id,
              documentType: element.registrationDocument.name,
              document: element.uploadedFile && element.uploadedFile.name ? element.uploadedFile.name : '-',
              documentUrl: element.uploadedFile && element.uploadedFile.url ? element.uploadedFile.url : '',
              template: element.templateFile && element.templateFile.name ? element.templateFile.name : '-',
              templateUrl: element.templateFile && element.templateFile.url ? element.templateFile.url : '',
              uploader: element.uploader,
              status: this.statusFriendly(element.status),
              lastUpdate: moment(element.updatedDate).format('DD MMM YYYY HH:mm')
            })
          })
        }
        setTimeout(() => {
          this.isLoading = false
          this.hideLoading()
        }, 1000)
      })
    },
    showVueToast(text, status, duration) {
      this.$toasted.show(text, {
        position: 'top-center',
        type: status,
        duration,
        theme: 'bubble',
        singleton: true
      })
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  &::v-deep .vgt-table thead th {
    white-space: nowrap;
  }
}
</style>
<style lang="scss" scoped>
.rounded-lg {
  border-radius: 0.5rem !important;
}
.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #58595b;
  }

  & + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #3b82f6;
    border-radius: 4px;
    margin-right: 10px;
  }

  &:hover + label:before {
    background: #3b82f6;
    opacity: 0.3;
  }

  &:checked + label:before {
    background: #3b82f6;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
</style>
