var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Modal', {
    attrs: {
      "modalVisible": _vm.isModalFeedbackDocument,
      "id": "feedback"
    },
    on: {
      "close": function close($event) {
        return _vm.hideModalFeedbackDocument();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-5"
  }, [_vm._v("Are you sure to " + _vm._s(_vm.action) + _vm._s(_vm.action === 'mark as complete' ? '' : ' document') + "?")]), _c('div', {
    staticClass: "flex justify-center items-center gap-2"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Yes",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.actionFeedback(_vm.action);
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "No",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.hideModalFeedbackDocument();
      }
    }
  })], 1)])])], 2), _c('Modal', {
    staticClass: "overflow-y-hidden",
    attrs: {
      "height": "430px",
      "width": "500px",
      "modalVisible": _vm.isModalAddDocument,
      "id": "addDocument"
    },
    on: {
      "close": function close($event) {
        return _vm.hideModalAddDocument();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('h1', {
    staticClass: "text-2xl font-bold mb-3 mt-5"
  }, [_vm._v("Add Document Request")]), _c('div', {
    staticClass: "mb-8"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Document Type")]), _c('Dropdown', {
    attrs: {
      "options": _vm.documentTypeOptions,
      "optionLabel": "name",
      "default": _vm.selectedDocumentType,
      "placeholder": "Select document type"
    },
    model: {
      value: _vm.selectedDocumentType,
      callback: function callback($$v) {
        _vm.selectedDocumentType = $$v;
      },
      expression: "selectedDocumentType"
    }
  })], 1), _c('div', {
    staticClass: "mb-8"
  }, [_c('label', {
    staticClass: "text-xs pl-3 font-medium text-neutral-500 text-left block mb-1"
  }, [_vm._v("Uploader "), !_vm.selectedDocumentType ? _c('span', {
    staticClass: "inline-block text-neutral-300"
  }, [_vm._v("(Document type required)")]) : _vm._e()]), _c('Dropdown', {
    attrs: {
      "disabled": !_vm.selectedDocumentType,
      "options": _vm.uploaderOptions,
      "default": _vm.selectedUploader,
      "placeholder": "Select uploader"
    },
    model: {
      value: _vm.selectedUploader,
      callback: function callback($$v) {
        _vm.selectedUploader = $$v;
      },
      expression: "selectedUploader"
    }
  })], 1), _c('div', {
    staticClass: "text-left mb-8"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isTemplateAvailable,
      expression: "isTemplateAvailable"
    }],
    staticClass: "styled-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "template"
    },
    domProps: {
      "checked": Array.isArray(_vm.isTemplateAvailable) ? _vm._i(_vm.isTemplateAvailable, null) > -1 : _vm.isTemplateAvailable
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.isTemplateAvailable,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isTemplateAvailable = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isTemplateAvailable = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isTemplateAvailable = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "text-sm",
    attrs: {
      "for": "template"
    }
  }, [_vm._v("Provide document template")])]), _c('div', {
    staticClass: "flex justify-center items-center my-5"
  }, [_c('Button', {
    staticClass: "mr-5",
    attrs: {
      "disabled": !_vm.canAddDocument,
      "buttonText": "Add Document",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.addDocument();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.hideModalAddDocument();
      }
    }
  })], 1)])], 2), _c('Modal', {
    staticClass: "overflow-y-hidden",
    attrs: {
      "width": "500px",
      "modalVisible": _vm.isModalUploadDocument,
      "id": "uploadDocument"
    },
    on: {
      "close": function close($event) {
        return _vm.hideModalUploadDocument();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "p-7"
  }, [_c('p', {
    staticClass: "text-center text-xl font-bold mb-2"
  }, [_vm._v("Upload Document")]), _c('div', {
    staticClass: "flex justify-center items-center"
  }, [_c('div', {
    staticClass: "w-full py-5 text-center"
  }, [_c('div', {
    class: "p-5 h-48 bg-white border rounded-lg border-dashed ".concat(_vm.fileDocument ? 'border-yellow' : 'border-gray-300'),
    on: {
      "dragover": _vm.dragover,
      "dragleave": _vm.dragleave,
      "drop": _vm.drop
    }
  }, [_vm.fileDocument ? _c('div', {
    staticClass: "h-full"
  }, [_c('div', {
    staticClass: "flex justify-end items-center"
  }, [_c('div', {
    staticClass: "cursor-pointer rounded-full hover:opacity-80",
    on: {
      "click": function click($event) {
        return _vm.remove();
      }
    }
  }, [_c('CrossCircle', {
    attrs: {
      "color": "red",
      "height": "24",
      "width": "24"
    }
  })], 1)]), _c('div', {
    staticClass: "flex flex-col justify-center items-center"
  }, [_c('DocumentText', {
    staticClass: "mb-2",
    attrs: {
      "color": "yellow",
      "height": "50",
      "width": "50"
    }
  }), _c('p', {
    staticClass: "text-yellow"
  }, [_vm._v(_vm._s(_vm.newName.length > 70 ? _vm.newName.substr(0, 68) + '..' : _vm.newName))])], 1)]) : _c('div', [_c('div', {
    staticClass: "flex justify-center mb-5"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/vector.svg")
    }
  })]), _c('input', {
    ref: "file",
    staticClass: "w-px h-px opacity-0 overflow-hidden absolute",
    attrs: {
      "type": "file",
      "id": "assetsFieldHandle",
      "accept": ".pdf,.jpg,.jpeg,.png,.webp"
    },
    on: {
      "change": _vm.onChange
    }
  }), _vm._v(" Drag & Drop File "), _c('br'), _vm._v("or "), _c('label', {
    staticClass: "block",
    attrs: {
      "for": "assetsFieldHandle"
    }
  }, [_c('div', {
    staticClass: "flex justify-center items-center"
  }, [_c('div', {
    staticClass: "cursor-pointer font-bold rounded-lg border text-xs py-2 px-3 text-white border-yellow bg-yellow hover:border-yellow-300 hover:bg-yellow-300"
  }, [_vm._v("Select File")])])])])])])]), _c('div', {
    staticClass: "flex justify-center items-center my-5"
  }, [_c('Button', {
    staticClass: "mr-5",
    attrs: {
      "disabled": !_vm.fileDocument,
      "buttonText": "Upload",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.forGetUploadUrl();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.hideModalUploadDocument();
      }
    }
  })], 1)])])], 2), _c('div', {
    staticClass: "mb-6 flex justify-start items-center"
  }, [_c('router-link', {
    staticClass: "flex justify-start items-center",
    attrs: {
      "to": "/crm/student/".concat(_vm.studentId)
    }
  }, [_c('ArrowForward', {
    attrs: {
      "direction": "left",
      "width": "25",
      "height": "25"
    }
  })], 1), _c('h1', {
    staticClass: "text-3xl font-bold ml-3 hover:no-underline"
  }, [_vm._v("Student Class Detail")])], 1), _vm.isLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', {
    staticClass: "min-h-screen"
  }, [_c('div', {
    staticClass: "bg-white p-4 shadow-small rounded-lg card"
  }, [_c('div', {
    staticClass: "px-2 flex-1 flex-shrink-0"
  }, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Profile Detail ")]), _c('div', {
    staticClass: "mb-6"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('p', {
    staticClass: "text-xs font-semibold text-neutral-400"
  }, [_vm._v("Full Name:")]), _c('p', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(_vm.studentName))])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('p', {
    staticClass: "text-xs font-semibold text-neutral-400"
  }, [_vm._v("Email:")]), _c('p', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(_vm.studentEmail))])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('p', {
    staticClass: "text-xs font-semibold text-neutral-400"
  }, [_vm._v("Phone Number:")]), _c('p', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(_vm.studentPhone))])])])]), _c('div', {
    staticClass: "px-2 flex-1 flex-shrink-0"
  }, [_c('p', {
    staticClass: "font-semibold mb-4 flex gap-1 items-center border-b border-neutral-50 py-1"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Class ")]), _c('div', {
    staticClass: "mb-6"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('p', {
    staticClass: "text-xs font-semibold text-neutral-400"
  }, [_vm._v("Program:")]), _c('p', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(_vm.programName))])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('p', {
    staticClass: "text-xs font-semibold text-neutral-400"
  }, [_vm._v("Class:")]), _c('p', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(_vm.coreClassName))])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('p', {
    staticClass: "text-xs font-semibold text-neutral-400"
  }, [_vm._v("Payment Method:")]), _c('p', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(_vm.paymentMethodName))])])])]), _c('div', {
    staticClass: "px-2 flex-1 flex-shrink-0"
  }, [_c('div', {
    staticClass: "flex justify-between border-b border-neutral-50 pb-2 mb-4"
  }, [_c('p', {
    staticClass: "font-semibold flex gap-1 items-center"
  }, [_c('span', {
    staticClass: "-ml-1"
  }, [_c('Label', {
    attrs: {
      "color": "yellow"
    }
  })], 1), _vm._v("Administration Document ")]), _c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('Button', {
    staticClass: "mr-2",
    attrs: {
      "disabled": _vm.statusId !== '1',
      "buttonText": "Mark as Complete",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.actionDocument('-', 'mark as complete');
      }
    }
  }), _c('Button', {
    attrs: {
      "disabled": _vm.statusId !== '1',
      "buttonText": "Add Document"
    },
    on: {
      "action": _vm.showModalAddDocument
    }
  })], 1)]), _c('div', {
    staticClass: "mb-6"
  }, [_vm.administrationList.length > 0 ? _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "rows": _vm.administrationList,
      "columns": _vm.tableHeader,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field == 'action' ? _c('div', {
          staticClass: "flex flex-col gap-2 whitespace-nowrap"
        }, [_c('div', {
          staticClass: "flex justify-center items-center"
        }, [_c('Button', {
          staticClass: "mr-2",
          attrs: {
            "disabled": props.row.uploader.toLowerCase() === 'student' || _vm.statusId !== '1' ? true : false,
            "type": "primary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Uploads');
            },
            "tooltip": {
              show: true,
              position: '70%',
              text: 'Upload File'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.actionDocument(props.row.id, 'upload');
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "disabled": props.row.documentUrl === '' || props.row.status.toLowerCase() === 'approved' || _vm.statusId !== '1',
            "type": "greenTertiary",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/CheckmarkCircle');
            },
            "tooltip": {
              show: true,
              position: '120%',
              text: 'Approve'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.actionDocument(props.row.id, 'approve');
            }
          }
        }), _c('Button', {
          staticClass: "mr-2",
          attrs: {
            "disabled": props.row.documentUrl === '' || props.row.status.toLowerCase() === 'rejected' || _vm.statusId !== '1',
            "type": "deleteTertiary",
            "size": "icon",
            "forIcon": {
              height: '24',
              width: '24',
              color: props.row.documentUrl === '' || props.row.status.toLowerCase() === 'rejected' || _vm.statusId !== '1' ? 'white' : 'red'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/CrossCircle');
            },
            "tooltip": {
              show: true,
              position: '140%',
              text: 'Reject'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.actionDocument(props.row.id, 'reject');
            }
          }
        }), _c('Button', {
          attrs: {
            "disabled": _vm.statusId !== '1',
            "type": "delete",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Trash');
            },
            "tooltip": {
              show: true,
              position: '140%',
              text: 'Delete'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.actionDocument(props.row.id, 'delete');
            }
          }
        })], 1)]) : props.column.field == 'document' ? _c('div', [_c('p', {
          class: props.row['documentUrl'] !== '' ? 'text-yellow cursor-pointer' : '',
          on: {
            "click": function click($event) {
              return _vm.viewDocument(props.row['documentUrl']);
            }
          }
        }, [_vm._v(_vm._s(props.row['document']))]), _c('p', {
          staticClass: "text-sm"
        }, [_vm._v(" Template: "), _c('span', {
          class: props.row['templateUrl'] !== '' ? 'text-yellow cursor-pointer' : '',
          on: {
            "click": function click($event) {
              return _vm.viewDocument(props.row['templateUrl']);
            }
          }
        }, [_vm._v(_vm._s(props.row['template']))])])]) : _c('div', [_vm._v(" " + _vm._s(props.row[props.column.field]) + " ")])];
      }
    }], null, false, 1827015008)
  }) : _c('div', {
    staticClass: "border rounded-lg h-64 flex justify-center items-center"
  }, [_c('div', {
    staticClass: "bg-pink"
  }, [_c('p', {
    staticClass: "text-center text-neutral-400"
  }, [_vm._v("No data available")])])])], 1)])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }